function grattisAjax (method, url, cb) {
  var xmlhttp = new XMLHttpRequest()
  xmlhttp.onreadystatechange = function () {
    if (xmlhttp.readyState !== 4) return
    if (xmlhttp.status === 200) {
      var data = null
      if (xmlhttp.responseText) {
        try {
          data = JSON.parse(xmlhttp.responseText)
        } catch (err) {
          return cb(err)
        }
      }
      cb(null, data)
    } else {
      cb(new Error(xmlhttp.status))
    }
  }
  xmlhttp.open(method, url, true)
  xmlhttp.send()
}

window.grattisRequest = {
  get: function (url, cb) {
    grattisAjax('GET', url, cb)
  }
};

(function (window, document, key, host, cdn) {

  if (window[key].length === 0) {
    return null;
  }

  var cnst = {
    KEY: 'gscm',
    MESSAGE_SET_HEIGHT: 1
  }

  window.addEventListener('message', function (event) {
    var data = event.data

    if (!data || !data.type) {
      return
    }

    switch (data.type) {
      case cnst.MESSAGE_SET_HEIGHT: {
        var id = data.id
        var height = data.ch + 4

        var iframe = document.getElementById(cnst.KEY + '_' + id)
        if (!iframe) return
        iframe.style['height'] = height + 'px'

        var div = document.getElementsByClassName(cnst.KEY + '_' + id)[0]
        if (!div) return
        div.style['height'] = (height + 10) + 'px'
        div.style['display'] = 'block'
      }
        break;
    }
  }, false);

  var id = window[key].shift()
  var items = document.getElementsByClassName(cnst.KEY + '_' + id) || []
  for (var i = 0; i < items.length; i++) {
    var item = items[i]
    if (item.innerHTML === '') {
      (function (item) {
        item.style['display'] = 'none'
        item.style['transition'] = 'height .3s'
        item.style['position'] = 'relative'
        item.style['overflow'] = 'hidden'
        item.style['margin'] = '0'
        item.style['padding'] = '0'
        item.style['text-align'] = 'center'
        item.style['height'] = '100%'
        item.style['width'] = '100%'

        grattisRequest.get('//' + host + '/api/ShowcaseMenu/' + id + '/cache', function (err, data) {
          if (err) {
            console.log(err)
            return
          }

          var url = '//' + cdn + '/view/' + id + '?v=' + data.v + '|' + data.g

          var iframe = document.createElement('iframe')
          iframe.style['height'] = window.screen.height + 'px'
          iframe.style['border'] = '0'
          iframe.style['width'] = '1px'
          iframe.style['min-width'] = '100%'
          iframe.style['*width'] = '100%'
          iframe.setAttribute('id', cnst.KEY + '_' + id)
          iframe.setAttribute('align', 'middle')
          iframe.setAttribute('frameborder', 'no')
          iframe.setAttribute('scrolling', 'no')
          iframe.setAttribute('allowfullscreen', '')
          iframe.setAttribute('allowtransparency', '')
          iframe.setAttribute('src', url)
          item.appendChild(iframe)
        })
      })(item)
    }
  }
})(this, this.document, 'grattisShowcaseMenu', 'showcase-menu.grattis.ru', 'cdn-showcase-menu.grattis.ru');
